import logo from "./logo.svg";
import React, { useState } from "react";
import "./App.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyChaG5eC3x5PeqeVLG3wALV5wqyPU5VSiY",
    authDomain: "jayandlark-2a6d9.firebaseapp.com",
    projectId: "jayandlark-2a6d9",
    storageBucket: "jayandlark-2a6d9.appspot.com",
    messagingSenderId: "136705318267",
    appId: "1:136705318267:web:20715213b6f2d548ea31de",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

function App() {
    const [tangoGrams, setTangoGrams] = useState(0);
    const [phinneyGrams, setPhinneyGrams] = useState(0);
    const [jacksonGrams, setJacksonGrams] = useState(0);
    const [tangoBag, setTangoBag] = useState("");
    const [phinneyBag, setPhinneyBag] = useState("");
    const [jacksonBag, setJacksonBag] = useState("");

    const changeHandler = (e) => {
        if (e.target.id === "tango") {
            setTangoBag(e.target.value);
            setTangoGrams(Math.round((+e.target.value - 40) / 8));
        } else if (e.target.id === "phinney") {
            setPhinneyBag(e.target.value);
            setPhinneyGrams(Math.round((+e.target.value - 30) / 8));
        } else if (e.target.id === "jackson") {
            setJacksonBag(e.target.value);
            setJacksonGrams(Math.round((+e.target.value - 30) / 8));
        }
    };

    return (
        <div className="App">
            <h3>Tango</h3>
            <input id="tango" type="number" onChange={changeHandler} value={tangoBag} />
            <h3>Phinney</h3>
            <input id="phinney" type="number" onChange={changeHandler} value={phinneyBag} />
            <h3>Jackson</h3>
            <input id="jackson" type="number" onChange={changeHandler} value={jacksonBag} />
            <h3>Container</h3>
            <input id="containera" type="radio" onChange={changeHandler} name="container" />
            <label htmlFor="containera">A</label>
            <input id="containerb" type="radio" onChange={changeHandler} name="container" />
            <label htmlFor="containerb">B</label>
            <h3>Tango: {tangoGrams}</h3>
            <h3>Phinney: {phinneyGrams}</h3>
            <h3>Jackson: {jacksonGrams}</h3>
        </div>
    );
}

export default App;
